import React, { useState } from "react";
import "./login.css";
import { fb } from "./firebase";
// import { useHistory } from "react-router-dom";

export default function Login({handleAuth}) {
  const [state, setState] = useState({
    email: "",
    password: "",
    auth: false,
  });
//   const history = useHistory();

  const submit = (e) => {
    e.preventDefault();
    // console.log(state);
    // history.push("/bo");
    fb.auth()
      .signInWithEmailAndPassword(state.email, state.password)
      .then((user) => {
        console.log(user);
        handleAuth();
      })
      .catch(function (error) {
        // Handle Errors here.
        console.log("error")
        var errorCode = error.code;
        var errorMessage = error.message;
        if (errorCode === "auth/wrong-password") {
          alert("Wrong password.");
        } else {
          alert(errorMessage);
        }
        console.log(error);
        // handleAuth();
      });
  };

  return (
    <div className="container" style={{ padding: "4em", marginTop: "5em" }}>
      <div className="row justify-content-center">
        <div className="col-md-6 col-sm-8 col-xs-10">
          <div className="card" style={{ padding: "1em" }}>
            <div className="card-body">
              <img
                src="SRETLOGO.png"
                alt="SRETLOGO"
                style={{
                  width: "100%",
                  alignSelf: "center",
                  marginBottom: 20,
                  // backgroundColor:'red'
                }}
              />
              <div className="form-group">
                <label htmlFor="exampleInputEmail1">Email address</label>
                <input
                  type="email"
                  className="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  placeholder="Enter email"
                  value={state.email}
                  onChange={(e) => {
                    //   console.log(state);
                    setState({
                      password: state.password,
                      email: e.target.value,
                    });
                  }}
                />
                <small id="emailHelp" className="form-text text-muted">
                  We'll never share your email with anyone else.
                </small>
              </div>
              <div className="form-group">
                <label htmlFor="exampleInputPassword1">Password</label>
                <input
                  type="password"
                  className="form-control"
                  id="exampleInputPassword1"
                  placeholder="Password"
                  value={state.password}
                  onChange={(e) => {
                    //   console.log(state);
                    setState({ email: state.email, password: e.target.value });
                  }}
                />
              </div>
              <div className="row justify-content-center">
                <button
                  type="submit"
                  className="btn btn-primary"
                  style={{ alignSelf: "center" }}
                  onClick={submit}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
