import fire from "firebase/app";
import "firebase/firestore";
import "firebase/auth";

var firebaseConfig = {
  apiKey: "AIzaSyAIYrXATUADXy52KPDihTkXzX8nNDEOwFI",
  authDomain: "final-502b9.firebaseapp.com",
  databaseURL: "https://final-502b9.firebaseio.com",
  projectId: "final-502b9",
  storageBucket: "final-502b9.appspot.com",
  messagingSenderId: "999665470323",
  appId: "1:999665470323:web:453847ed0212adc87a1306",
  measurementId: "G-7VZWZP2P3Z",
};
// Initialize Firebase
export const fb = fire.initializeApp(firebaseConfig);
export const db = fire.firestore().settings({ timestampsInSnapshots: true });
// fire.analytics();
// export const increment = fire.firestore.FieldValue.increment(1)
