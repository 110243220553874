import {
  ColumnDirective,
  ColumnsDirective,
  Page,
  TreeGridComponent,
} from "@syncfusion/ej2-react-treegrid";
import {
  ExcelExport,
  Inject,
  Toolbar,
  Sort,
  Filter,
  Reorder,
  Resize,
  CommandColumn,
} from "@syncfusion/ej2-react-treegrid";
import { closest } from "@syncfusion/ej2-base";
import Modal from "react-modal";
// import $ from "jquery";

import * as React from "react";
import "./App.css";
import { getRefresh } from "./fetchData";

// Modal.setAppElement("#myModel")

export default class BO extends React.Component {
  constructor() {
    super(...arguments);
    this.toolbarOptions = ["ExcelExport"];
    this.pageSettings = { pageSizes: [10, 25, 50, 75, 100, 200], pageSize: 10 };
    this.sortingOptions = {
      columns: [{ field: "Application ID", direction: "Ascending" }],
    };
    this.state = {
      sortData: [],
      allData: [],
      oldData: [],
      auth: false,
      type: 0,
      data: {},
      modal: false,
      imageFlag: true,
      cimageFlag: true,
      appId: null,
    };
    this.commands = [
      {
        buttonOption: {
          click: this.onClick.bind(this),
          content: "SHOW",
          cssClass: "e-flat",
        },
      },
    ];
    this.allModules = this.allModules.bind(this);
    this.BTech = this.BTech.bind(this);
    this.MTech = this.MTech.bind(this);
    this.Phd = this.Phd.bind(this);
    this.Old = this.Old.bind(this);
    this.Full = this.Full.bind(this);
  }
  componentDidMount() {
    console.log("componentDidMount");
    if (this.state.allData.length === 0) {
      const postData = JSON.parse(localStorage.getItem("data"));
      const oldData = JSON.parse(localStorage.getItem("olddata"));
      // console.log(postData)
      this.setState({
        sortData: postData,
        allData: postData,
        oldData: oldData,
      });
      setTimeout(() => {
        console.log(this.state);
        this.setState({ auth: true });
      }, 500);
    }
  }
  onClick(args) {
    if (this.treegrid) {
      const rowObj = this.treegrid.grid.getRowObjectFromUID(
        closest(args.target, ".e-row").getAttribute("data-uid")
      );
      const rowData = rowObj.data;
      // alert(JSON.stringify(rowData));
      this.setState({ data: rowData });
      this.setState({ appId: this.state.data.newId.appId });
      console.log(this.state.data);
      // console.log(this.state.data.newId.appId);

      this.setState({ modal: true });
      // $("document").ready(function () {
      //   $("#myModal").modal("show");
      // });
    }
  }
  toolbarClick(args) {
    if (this.treegrid && args.item.text === "Excel Export") {
      this.treegrid.excelExport();
    }
  }

  allModules() {
    if (this.state.allData.length > 0)
      return (
        <TreeGridComponent
          style={{ width: "100%" }}
          dataSource={this.state.sortData}
          treeColumnIndex={1}
          allowPaging="true"
          allowSorting="true"
          allowFiltering="true"
          allowReordering="true"
          allowResizing="true"
          allowExcelExport="true"
          ref={(treegrid) => (this.treegrid = treegrid)}
          pageSettings={this.pageSettings}
          toolbarClick={this.toolbarClick}
          toolbar={this.toolbarOptions}
        >
          <ColumnsDirective>
            <ColumnDirective
              field="newId.appId"
              headerText="Application ID"
              width="135"
            />
            <ColumnDirective
              field="candidatename"
              headerText="Candidate Name"
            />
            <ColumnDirective field="email" headerText="Email" />
            <ColumnDirective field="mobile" headerText="Mobile Number" />
            <ColumnDirective field="board" headerText="Board" />
            <ColumnDirective field="Department" headerText="Course" />
            <ColumnDirective field="payday" headerText="Payment Date" />
            <ColumnDirective
              headerText="Commands"
              width="120"
              commands={this.commands}
            />
          </ColumnsDirective>
          <Inject
            services={[
              Page,
              Sort,
              Filter,
              Toolbar,
              ExcelExport,
              Reorder,
              Resize,
              CommandColumn,
            ]}
          />
        </TreeGridComponent>
      );
    else return <div style={{ padding: "30em" }}>Loading</div>;
  }

  Full() {
    if (this.state.allData.length > 0)
      return (
        <TreeGridComponent
          style={{ width: "100%" }}
          dataSource={this.state.sortData}
          treeColumnIndex={1}
          allowPaging="true"
          allowSorting="true"
          allowFiltering="true"
          allowReordering="true"
          allowResizing="true"
          allowExcelExport="true"
          ref={(treegrid) => (this.treegrid = treegrid)}
          pageSettings={this.pageSettings}
          toolbarClick={this.toolbarClick}
          toolbar={this.toolbarOptions}
        >
          <ColumnsDirective>
            <ColumnDirective
              field="newId.appId"
              headerText="Application ID"
              width="135"
            />
            <ColumnDirective
              field="candidatename"
              headerText="Candidate Name"
            />
            <ColumnDirective field="email" headerText="Email" />
            <ColumnDirective field="mobile" headerText="Mobile Number" />
            <ColumnDirective field="board" headerText="Board" />
            <ColumnDirective field="Department" headerText="Course" />
            <ColumnDirective field="payday" headerText="Payment Date" />
            <ColumnDirective field="school" headerText="School Name" />
            <ColumnDirective field="schaddress" headerText="School Address" />
            <ColumnDirective field="schcity" headerText="School City" />
            <ColumnDirective
              field="schooldistrict"
              headerText="School District"
            />
            <ColumnDirective field="schstate" headerText="School State" />
            <ColumnDirective field="schoolpin" headerText="School Pin" />

            <ColumnDirective
              headerText="Commands"
              width="120"
              commands={this.commands}
            />
          </ColumnsDirective>
          <Inject
            services={[
              Page,
              Sort,
              Filter,
              Toolbar,
              ExcelExport,
              Reorder,
              Resize,
              CommandColumn,
            ]}
          />
        </TreeGridComponent>
      );
    else return <div style={{ padding: "30em" }}>Loading</div>;
  }

  BTech() {
    if (this.state.allData.length > 0)
      return (
        <TreeGridComponent
          style={{ width: "100%" }}
          dataSource={this.state.sortData.filter(
            (data) =>
              data.Department === "B.Tech." || data.Department === "B.Sc."
          )}
          treeColumnIndex={1}
          allowPaging="true"
          allowSorting="true"
          allowFiltering="true"
          allowReordering="true"
          allowResizing="true"
          allowExcelExport="true"
          ref={(treegrid) => (this.treegrid = treegrid)}
          pageSettings={this.pageSettings}
          toolbarClick={this.toolbarClick}
          toolbar={this.toolbarOptions}
        >
          <ColumnsDirective>
            <ColumnDirective
              field="newId.appId"
              headerText="Application ID"
              width="135"
            />
            <ColumnDirective
              width="100"
              field="candidatename"
              headerText="Candidate Name"
            />
            <ColumnDirective width="100" field="email" headerText="Email" />
            <ColumnDirective
              width="100"
              field="mobile"
              headerText="Mobile Number"
            />
            <ColumnDirective width="100" field="board" headerText="Board" />
            <ColumnDirective
              width="100"
              field="Department"
              headerText="Course"
            />
            <ColumnDirective width="150" field="info" headerText="Selection" />
            <ColumnDirective
              width="100"
              field="payday"
              headerText="Payment Date"
            />
            <ColumnDirective
              headerText="Commands"
              width="120"
              commands={this.commands}
            />
          </ColumnsDirective>
          <Inject
            services={[
              Page,
              Sort,
              Filter,
              Toolbar,
              ExcelExport,
              Reorder,
              Resize,
              CommandColumn,
            ]}
          />
        </TreeGridComponent>
      );
  }

  MTech() {
    if (this.state.allData.length > 0)
      return (
        <TreeGridComponent
          style={{ width: "100%" }}
          dataSource={this.state.sortData.filter(
            (data) =>
              data.Department === "M.Tech." || data.Department === "M.Sc."
          )}
          treeColumnIndex={1}
          allowPaging="true"
          allowSorting="true"
          allowFiltering="true"
          allowReordering="true"
          allowResizing="true"
          allowExcelExport="true"
          ref={(treegrid) => (this.treegrid = treegrid)}
          pageSettings={this.pageSettings}
          toolbarClick={this.toolbarClick}
          toolbar={this.toolbarOptions}
        >
          <ColumnsDirective>
            <ColumnDirective
              field="newId.appId"
              headerText="Application ID"
              width="135"
            />
            <ColumnDirective
              field="candidatename"
              width="100"
              headerText="Candidate Name"
            />
            <ColumnDirective field="email" width="135" headerText="Email" />
            <ColumnDirective
              field="mobile"
              width="100"
              headerText="Mobile Number"
            />
            <ColumnDirective field="Program" width="100" headerText="UG" />
            <ColumnDirective field="info" width="150" headerText="Selection" />
            <ColumnDirective
              field="payday"
              width="100"
              headerText="Payment Date"
            />
            <ColumnDirective
              headerText="Commands"
              width="100"
              commands={this.commands}
            />
          </ColumnsDirective>
          <Inject
            services={[
              Page,
              Sort,
              Filter,
              Toolbar,
              ExcelExport,
              Reorder,
              Resize,
              CommandColumn,
            ]}
          />
        </TreeGridComponent>
      );
  }

  Phd() {
    if (this.state.allData.length > 0)
      return (
        <TreeGridComponent
          style={{ width: "100%" }}
          dataSource={this.state.sortData.filter(
            (data) => data.Department === "Ph.D."
          )}
          treeColumnIndex={1}
          allowPaging="true"
          allowSorting="true"
          allowFiltering="true"
          allowReordering="true"
          allowResizing="true"
          allowExcelExport="true"
          ref={(treegrid) => (this.treegrid = treegrid)}
          pageSettings={this.pageSettings}
          toolbarClick={this.toolbarClick}
          toolbar={this.toolbarOptions}
        >
          <ColumnsDirective>
            <ColumnDirective
              field="newId.appId"
              headerText="Application ID"
              width="135"
            />
            <ColumnDirective
              field="candidatename"
              width="100"
              headerText="Candidate Name"
            />
            <ColumnDirective field="email" width="135" headerText="Email" />
            <ColumnDirective
              field="mobile"
              width="100"
              headerText="Mobile Number"
            />
            <ColumnDirective field="Program" width="100" headerText="UG" />
            <ColumnDirective field="PgProgram" width="100" headerText="PG" />
            <ColumnDirective
              field="Department"
              width="80"
              headerText="Course"
            />
            <ColumnDirective
              field="payday"
              width="100"
              headerText="Payment Date"
            />
            <ColumnDirective
              headerText="Commands"
              width="100"
              commands={this.commands}
            />
          </ColumnsDirective>
          <Inject
            services={[
              Page,
              Sort,
              Filter,
              Toolbar,
              ExcelExport,
              Reorder,
              Resize,
              CommandColumn,
            ]}
          />
        </TreeGridComponent>
      );
  }

  Old() {
    if (this.state.oldData.length > 0)
      return (
        <TreeGridComponent
          style={{ width: "100%" }}
          dataSource={this.state.oldData}
          treeColumnIndex={1}
          allowPaging="true"
          allowSorting="true"
          allowFiltering="true"
          allowReordering="true"
          allowResizing="true"
          allowExcelExport="true"
          ref={(treegrid) => (this.treegrid = treegrid)}
          pageSettings={this.pageSettings}
          toolbarClick={this.toolbarClick}
          toolbar={this.toolbarOptions}
        >
          <ColumnsDirective>
            <ColumnDirective
              field="newId.appId"
              headerText="Application ID"
              width="135"
            />
            <ColumnDirective
              field="candidatename"
              width="100"
              headerText="Candidate Name"
            />
            <ColumnDirective field="email" width="135" headerText="Email" />
            <ColumnDirective
              field="mobile"
              width="100"
              headerText="Mobile Number"
            />
            <ColumnDirective field="Program" width="100" headerText="UG" />
            <ColumnDirective field="PgProgram" width="100" headerText="PG" />
            <ColumnDirective
              field="Department"
              width="80"
              headerText="Course"
            />
            <ColumnDirective
              field="payday"
              width="100"
              headerText="Payment Date"
            />
            <ColumnDirective
              headerText="Commands"
              width="100"
              commands={this.commands}
            />
          </ColumnsDirective>
          <Inject
            services={[
              Page,
              Sort,
              Filter,
              Toolbar,
              ExcelExport,
              Reorder,
              Resize,
              CommandColumn,
            ]}
          />
        </TreeGridComponent>
      );
  }

  render() {
    this.toolbarClick = this.toolbarClick.bind(this);
    if (this.state.auth) {
      return (
        <div
          style={{
            margin: "2em",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <Modal
            isOpen={this.state.modal}
            contentLabel="Example Modal"
            onClick={() => {
              this.setState({ modal: false });
            }}
          >
            <div>
              <div className="row justify-content-between">
                <p style={{ fontSize: "1.4em", margin: "1em" }}>
                  <span style={{ fontWeight: "bold" }}>APPLICATION NO : </span>
                  <span
                    style={{
                      color: this.state.data.payflag === 1 ? "green" : "red",
                    }}
                  >
                    {this.state.appId}{" "}
                    {this.state.data.payflag === 1 ? "(PAID)" : "(UNPAID)"}
                  </span>
                </p>
                <h3
                  style={{
                    margin: "1em",
                  }}
                  onClick={() => {
                    this.setState({ modal: false });
                  }}
                >
                  x
                </h3>
              </div>
              <div
                className="row justify-content-around"
                // style={{ backgroundColor: "pink" }}
              >
                <div className="col-md-11 col-sm-10 row">
                  <div className="col-sm-6">
                    <p style={{ fontSize: "1em", margin: "0.5em" }}>
                      <span style={{ fontWeight: "bold" }}>Name : </span>
                      {this.state.data.candidatename}
                    </p>
                    {this.state.data.father && (
                      <p style={{ fontSize: "1em", margin: "0.5em" }}>
                        <span style={{ fontWeight: "bold" }}>
                          Father's Name :{" "}
                        </span>
                        {this.state.data.father}
                      </p>
                    )}
                    {this.state.data.mother && (
                      <p style={{ fontSize: "1em", margin: "0.5em" }}>
                        <span style={{ fontWeight: "bold" }}>
                          Mother's Name :{" "}
                        </span>
                        {this.state.data.mother}
                      </p>
                    )}
                    {this.state.data.guardian && (
                      <p style={{ fontSize: "1em", margin: "0.5em" }}>
                        <span style={{ fontWeight: "bold" }}>
                          Guardian's Name :{" "}
                        </span>
                        {this.state.data.guardian}
                      </p>
                    )}
                    <p style={{ fontSize: "1em", margin: "0.5em" }}>
                      <span style={{ fontWeight: "bold" }}>City : </span>
                      {this.state.data.city}
                    </p>
                    <p style={{ fontSize: "1em", margin: "0.5em" }}>
                      <span style={{ fontWeight: "bold" }}>DOB : </span>
                      {this.state.data.dob}
                    </p>
                    <p style={{ fontSize: "1em", margin: "0.5em" }}>
                      <span style={{ fontWeight: "bold" }}>Gender : </span>
                      {this.state.data.gender}
                    </p>
                    <p style={{ fontSize: "1em", margin: "0.5em" }}>
                      <span style={{ fontWeight: "bold" }}>Mobile : </span>
                      {this.state.data.mobile}
                    </p>
                    <p style={{ fontSize: "1em", margin: "0.5em" }}>
                      <span style={{ fontWeight: "bold" }}>Email : </span>
                      {this.state.data.email}
                    </p>
                    <p style={{ fontSize: "1em", margin: "0.5em" }}>
                      <span style={{ fontWeight: "bold" }}>Address : </span>
                      {this.state.data.addressa},{this.state.data.addressb}
                    </p>
                    <p style={{ fontSize: "1em", margin: "0.5em" }}>
                      <span style={{ fontWeight: "bold" }}>City : </span>
                      {this.state.data.city}
                    </p>
                    <p style={{ fontSize: "1em", margin: "0.5em" }}>
                      <span style={{ fontWeight: "bold" }}>State : </span>
                      {this.state.data.state}
                    </p>
                    <p style={{ fontSize: "1em", margin: "0.5em" }}>
                      <span style={{ fontWeight: "bold" }}>Pin : </span>
                      {this.state.data.pin}
                    </p>
                  </div>
                  <div className="col-sm-6">
                    {this.state.data.Department && (
                      <p style={{ fontSize: "1em", margin: "0.5em" }}>
                        <span style={{ fontWeight: "bold" }}>
                          Department :{" "}
                        </span>
                        {this.state.data.Department}
                      </p>
                    )}
                    {this.state.data.board && (
                      <p style={{ fontSize: "1em", margin: "0.5em" }}>
                        <span style={{ fontWeight: "bold" }}>Board : </span>
                        {this.state.data.board}
                      </p>
                    )}
                    {this.state.data.info && (
                      <p style={{ fontSize: "1em", margin: "0.5em" }}>
                        <span style={{ fontWeight: "bold" }}>Opted For : </span>
                        {this.state.data.info}
                      </p>
                    )}
                    {this.state.data.appearingmon &&
                      this.state.data.appearingyear && (
                        <p style={{ fontSize: "1em", margin: "0.5em" }}>
                          <span style={{ fontWeight: "bold" }}>
                            Appearing :{" "}
                          </span>
                          {this.state.data.appearingmon}{" "}
                          {this.state.data.appearingyear}
                        </p>
                      )}
                    {this.state.data.school && (
                      <p style={{ fontSize: "1em", margin: "0.5em" }}>
                        <span style={{ fontWeight: "bold" }}>School : </span>
                        {this.state.data.school}
                      </p>
                    )}
                    {this.state.data.schaddress && (
                      <p style={{ fontSize: "1em", margin: "0.5em" }}>
                        <span style={{ fontWeight: "bold" }}>
                          School Address :{" "}
                        </span>
                        {this.state.data.schaddress}
                      </p>
                    )}
                    {this.state.data.schcity && (
                      <p style={{ fontSize: "1em", margin: "0.5em" }}>
                        <span style={{ fontWeight: "bold" }}>
                          School City :{" "}
                        </span>
                        {this.state.data.schcity}
                      </p>
                    )}
                    {this.state.data.schooldistrict && (
                      <p style={{ fontSize: "1em", margin: "0.5em" }}>
                        <span style={{ fontWeight: "bold" }}>
                          School District :{" "}
                        </span>
                        {this.state.data.schooldistrict}
                      </p>
                    )}
                    {this.state.data.schstate && (
                      <p style={{ fontSize: "1em", margin: "0.5em" }}>
                        <span style={{ fontWeight: "bold" }}>
                          School State :{" "}
                        </span>
                        {this.state.data.schstate}
                      </p>
                    )}
                    {this.state.data.schoolpin && (
                      <p style={{ fontSize: "1em", margin: "0.5em" }}>
                        <span style={{ fontWeight: "bold" }}>School Pin: </span>
                        {this.state.data.schoolpin}
                      </p>
                    )}
                    {this.state.data.university && (
                      <p style={{ fontSize: "1em", margin: "0.5em" }}>
                        <span style={{ fontWeight: "bold" }}>University: </span>
                        {this.state.data.university}
                      </p>
                    )}
                    {this.state.data.Program && (
                      <p style={{ fontSize: "1em", margin: "0.5em" }}>
                        <span style={{ fontWeight: "bold" }}>Program: </span>
                        {this.state.data.Program}
                      </p>
                    )}
                    {this.state.data.score && (
                      <p style={{ fontSize: "1em", margin: "0.5em" }}>
                        <span style={{ fontWeight: "bold" }}>Score: </span>
                        {this.state.data.score}
                      </p>
                    )}
                    {this.state.data.PGcollege && (
                      <p style={{ fontSize: "1em", margin: "0.5em" }}>
                        <span style={{ fontWeight: "bold" }}>PG College: </span>
                        {this.state.data.PGcollege}
                      </p>
                    )}
                    {this.state.data.PgProgram && (
                      <p style={{ fontSize: "1em", margin: "0.5em" }}>
                        <span style={{ fontWeight: "bold" }}>PG Program: </span>
                        {this.state.data.PgProgram}
                      </p>
                    )}
                    {this.state.data.pgscore && (
                      <p style={{ fontSize: "1em", margin: "0.5em" }}>
                        <span style={{ fontWeight: "bold" }}>PG Score: </span>
                        {this.state.data.pgscore}
                      </p>
                    )}
                  </div>
                  <div className="col-sm-12 row justify-content-around">
                    <div>
                      <p style={{ fontSize: "1em", margin: "0.5em" }}>
                        <span style={{ fontWeight: "bold" }}>
                          Payment ID :{" "}
                        </span>
                        {this.state.data.successId}
                      </p>
                      <p style={{ fontSize: "1em", margin: "0.5em" }}>
                        <span style={{ fontWeight: "bold" }}>
                          Payment Day :{" "}
                        </span>
                        {this.state.data.payday}
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className="col-md-1 col-sm-2"
                  // style={{ backgroundColor: "gold" }}
                >
                  {this.state.data.imageUrl && (
                    <div className=" justify-content-around mb-2">
                      {/* <h3>USER IMAGE</h3> */}
                      <a
                        target="_blank"
                        href={this.state.data.imageUrl}
                        rel="noopener noreferrer"
                      >
                        <img
                          src={this.state.data.imageUrl}
                          style={{
                            height: "auto",
                            width: "100%",
                          }}
                          alt="UserImage"
                        />
                      </a>
                    </div>
                  )}
                  {this.state.data.MimageUrl && (
                    <div className=" justify-content-around mb-2">
                      {/* <h3>MARK SHEET</h3> */}
                      {this.state.imageFlag ? (
                        <a
                          href={this.state.data.MimageUrl}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            src={this.state.data.MimageUrl}
                            style={{
                              height: "auto",
                              width: "100%",
                            }}
                            alt="MarkSheet"
                            onError={() => {
                              this.setState({ imageFlag: false });
                            }}
                          />
                        </a>
                      ) : (
                        <div>
                          <embed
                            src={this.state.data.MimageUrl}
                            width="100%"
                            style={{ height: "8em" }}
                            height="auto"
                          />
                          <a
                            style={{ textAlign: "center" }}
                            href={this.state.data.MimageUrl}
                            rel="noopener noreferrer"
                            target="_blank"
                          >
                            {" "}
                            download
                          </a>
                        </div>
                      )}
                    </div>
                  )}
                  {this.state.data.CimageUrl && (
                    <div className=" justify-content-around mb-2">
                      {this.state.cimageFlag ? (
                        <a
                          href={this.state.data.CimageUrl}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            src={this.state.data.CimageUrl}
                            style={{
                              height: "auto",
                              width: "100%",
                            }}
                            alt="Certificate"
                            onError={() => {
                              this.setState({ cimageFlag: false });
                            }}
                          />
                        </a>
                      ) : (
                        <div>
                          <embed
                            src={this.state.data.CimageUrl}
                            width="100%"
                            style={{ height: "8em" }}
                            height="auto"
                          />
                          <a
                            style={{ textAlign: "center" }}
                            href={this.state.data.CimageUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {" "}
                            download
                          </a>
                        </div>
                      )}
                    </div>
                  )}
                  {!this.state.data.CimageUrl &&
                    !this.state.data.imageUrl &&
                    !this.state.data.MimageUrl && (
                      <div className="justify-content-around mb-2">
                        <h1 style={{ fontSize: "1em", marginTop: "8em" }}>
                          NO IMAGES ARE UPLOADED
                        </h1>
                      </div>
                    )}
                </div>
              </div>
            </div>
          </Modal>
          <img
            src="SRETLOGO.png"
            alt="SRETLOGO"
            style={{ width: "50%", alignSelf: "center", marginBottom: 20 }}
          />
          <div
            className="row"
            style={{
              padding: "1em",
              backgroundColor: "#f5f5f5",
              width: "100%",
            }}
          >
            <p
              style={{ fontSize: "1em", margin: "0.5em" }}
              onClick={() => this.setState({ type: 0 })}
            >
              <span
                style={{ fontWeight: this.state.type === 0 ? "bold" : "500" }}
              >
                ALL
              </span>
            </p>
            <p
              style={{ fontSize: "1em", margin: "0.5em" }}
              onClick={() => this.setState({ type: 1 })}
            >
              <span
                style={{ fontWeight: this.state.type === 1 ? "bold" : "500" }}
              >
                UG
              </span>
            </p>
            <p
              style={{ fontSize: "1em", margin: "0.5em" }}
              onClick={() => this.setState({ type: 2 })}
            >
              <span
                style={{ fontWeight: this.state.type === 2 ? "bold" : "500" }}
              >
                PG
              </span>
            </p>
            <p
              style={{ fontSize: "1em", margin: "0.5em" }}
              onClick={() => this.setState({ type: 3 })}
            >
              <span
                style={{ fontWeight: this.state.type === 3 ? "bold" : "500" }}
              >
                PHD
              </span>
            </p>
            <p
              style={{ fontSize: "1em", margin: "0.5em" }}
              onClick={() => this.setState({ type: 4 })}
            >
              <span
                style={{ fontWeight: this.state.type === 4 ? "bold" : "500" }}
              >
                OLD
              </span>
            </p>
            <p
              style={{ fontSize: "1em", margin: "0.5em" }}
              onClick={() => this.setState({ type: 5 })}
            >
              <span
                style={{ fontWeight: this.state.type === 5 ? "bold" : "500" }}
              >
                FULL
              </span>
            </p>
          </div>
          <div
            className="row justify-content-between"
            style={{ width: "100%" }}
          >
            <div className="row">
              <button
                className="btn btn-outline-primary"
                style={{ margin: "1em" }}
                onClick={() => {
                  this.setState({
                    sortData: this.state.allData.filter(
                      (data) => data.payflag === 1
                    ),
                  });
                }}
              >
                PAID
              </button>
              <button
                className="btn btn-outline-primary"
                style={{ margin: "1em" }}
                onClick={() => {
                  this.setState({
                    sortData: this.state.allData.filter(
                      (data) => data.payflag !== 1
                    ),
                  });
                }}
              >
                UNPAID
              </button>
              <button
                className="btn btn-outline-primary"
                style={{ margin: "1em" }}
                onClick={() => {
                  this.setState({
                    sortData: this.state.allData,
                  });
                }}
              >
                RESET
              </button>
              <button
                className="btn btn-outline-primary"
                style={{ margin: "1em" }}
                onClick={() => {
                  console.log("get refresh");
                  getRefresh();
                  const postData = JSON.parse(localStorage.getItem("data"));
                  // console.log(postData)
                  this.setState({
                    sortData: postData,
                    allData: postData,
                  });
                  setTimeout(() => {
                    this.setState({ type: 0 });
                    console.log(this.state);
                  }, 500);
                }}
              >
                REFRESH
              </button>
            </div>
            <button
              className="btn btn-outline-danger"
              style={{ margin: "1em" }}
              onClick={() => {
                localStorage.setItem("auth", false);
                this.props.logout();
              }}
            >
              Logout
            </button>
          </div>
          {this.state.type === 0 && <this.allModules />}
          {this.state.type === 1 && <this.BTech />}
          {this.state.type === 2 && <this.MTech />}
          {this.state.type === 3 && <this.Phd />}
          {this.state.type === 4 && <this.Old />}
          {this.state.type === 5 && <this.Full />}
        </div>
      );
    } else {
      return <div style={{ margin: "4em" }}>Loading</div>;
    }
  }
}
