import { fb } from "./firebase";

function getData() {
  fb.firestore()
    .collection("users2022")
    // .orderBy("newId.appId")
    .onSnapshot((snapshot) => {
      const postData = [];
      snapshot.forEach((doc) => postData.push({ ...doc.data(), id: doc.id }));
      localStorage.setItem("data", JSON.stringify(postData));
    });

  fb.firestore()
    .collection("users2021")
    // .orderBy("newId.appId")
    .onSnapshot((snapshot) => {
      const oldData = [];
      snapshot.forEach((doc) => oldData.push({ ...doc.data(), id: doc.id }));
      localStorage.setItem("olddata", JSON.stringify(oldData));
    });
}

function getRefresh() {
  fb.firestore()
    .collection("users2022")
    // .orderBy("newId.appId")
    .onSnapshot((snapshot) => {
      const postData = [];
      snapshot.forEach((doc) => postData.push({ ...doc.data(), id: doc.id }));
      localStorage.setItem("data", JSON.stringify(postData));
    });
}

// function getData() {
//     fb.firestore()
//         .collection("users2021")
//         // .orderBy("newId.appId")
//         .onSnapshot((snapshot) => {
//             const postData = [];
//             snapshot.forEach((doc) => postData.push({...doc.data(), id: doc.id }));
//             localStorage.setItem("data", JSON.stringify(postData));
//         });
// }

export { getData, getRefresh };
