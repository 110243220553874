import * as React from "react";
// import { BrowserRouter, Switch, Route } from "react-router-dom";

import "./App.css";
import BO from "./BO.js";

import Login from "./login";
import { getData } from "./fetchData";
export default class App extends React.Component {
  constructor() {
    super();
    this.state = {
      isAuthenticated: false,
      data: [],
    };
    this.handleAuth = this.handleAuth.bind(this);
    this.logout = this.logout.bind(this);
  }
  componentDidMount() {
    // this.setState({ isAuthenticated: false });
    // localStorage.setItem("auth", false);
    const auth = localStorage.getItem("auth");
    // console.log(auth === "false" ? true : false);
    this.setState({
      isAuthenticated: auth === "false" || auth === null ? false : true,
    });
  }

  handleAuth() {
      console.log("handleAuth")
      localStorage.setItem("auth", true);
      getData();
      this.setState({ data: JSON.parse(localStorage.getItem("data")) });
      setTimeout(() =>{
          this.setState({ isAuthenticated: true });
          console.log(this.state)
      },2000)
  }

  logout() {
    this.setState({ isAuthenticated: false });
    localStorage.setItem("auth", false);
  }

  render() {
    if (this.state.isAuthenticated) {
      return <BO logout={this.logout} postData={this.state.data} />;
    } else {
      return <Login handleAuth={this.handleAuth} />;
    }
    // return <div style={{ margin: "4em" }}>Loading</div>;
    // return (
    //   <BrowserRouter>
    //     <div className="App"></div>
    //       <Switch>
    //         <Route exact path="/" component={Login} />
    //         <Route exact path="/bo" component={BO} />
    //       </Switch>
    //     </div>
    //   </BrowserRouter>
    // );
  }
}
